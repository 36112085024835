import { Link } from '@dx-ui/osc-link';

export const ChatDisclaimer = () => {
  return (
    <div className="text-text-alt text-center font-sans text-xs leading-normal">
      <p>
        This is an automated chatbot to be used for general informational purposes only. By
        continuing to use this service, you acknowledge and accept that all interactions and
        responses are AI-generated and may produce inaccurate or incomplete information. Any hotel
        booking you make will be subject to the terms and conditions of that reservation. For more
        information, please refer to our&nbsp;
        <Link url="/en/p/site-usage-agreement/" className="underline" isNewWindow>
          Site Usage Agreement.
        </Link>
      </p>
      <p className="mt-4">
        By using this service, you consent to the recording of this chat session and to the use of
        the information you submit by Hilton and our service providers. For details about how Hilton
        processes your personal data, refer to the&nbsp;
        <Link url="/en/p/global-privacy-statement/" className="underline" isNewWindow>
          Hilton Global Privacy Statement.
        </Link>
      </p>
    </div>
  );
};
