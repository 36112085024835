import * as React from 'react';
import { ChatBubble } from './genai-chat.chat-bubble';
import { SkeletonLoader } from '@dx-ui/osc-skeleton-loader';

export const ChatBubbleLoading = React.forwardRef<HTMLLIElement>((_, ref) => (
  <ChatBubble ref={ref} chatRole="agent" data-testid="chat-bubble-loading">
    <div className="flex h-6 items-center justify-center gap-2">
      <span className="sr-only">AI Stay Planner is responding</span>
      <SkeletonLoader className="size-2.5" color="bg-text-alt" variant="circle" />
      <SkeletonLoader className="size-2.5" color="bg-text-alt" variant="circle" />
      <SkeletonLoader className="size-2.5" color="bg-text-alt" variant="circle" />
    </div>
  </ChatBubble>
));

ChatBubbleLoading.displayName = 'ChatBubbleLoading';
