import { AlphabetizedLinkItems } from './alphabetized-items';
import { useMediaQuery } from 'usehooks-ts';
import { useWrappedRouter } from '../../../hooks';
import getBreakpoint from '../../../utils/get-breakpoint';
import { env } from '../../../constants';
import type { LocationPageInterlink } from '@dx-ui/queries-dx-shop-search-ui';
import { Accordion } from '@dx-ui/osc-accordion';

type LocationsByAttributesProp = {
  isLoading?: boolean;
  pageInterLinks?: LocationPageInterlink[] | null;
};

export const LocationsByAttributes = ({ isLoading, pageInterLinks }: LocationsByAttributesProp) => {
  const { router } = useWrappedRouter();
  const isDesktop = useMediaQuery(`(min-width: ${getBreakpoint('lg')}px)`);
  const isTablet = useMediaQuery(`(min-width: ${getBreakpoint('md')}px)`);

  const columnCount = isDesktop ? 5 : isTablet ? 3 : 2;

  const hostAndlang = `${env.OHW_BASE_URL}${router.locale}/`;
  return !isLoading ? (
    <div className="bg-border-alt">
      {pageInterLinks?.map((interLink, index) => (
        <div
          className="bg-border-alt flex items-center px-4 pt-4 lg:px-10"
          key={`defaultLocations-pageInterlink-${interLink?.title}`}
        >
          <div className="border-border bg-text-inverse w-full border">
            <Accordion
              className="accordion-stacked hover:text-primary-alt px-2 pl-4"
              items={[
                {
                  collapsedButtonLabel: interLink?.title || '',
                  expandedButtonLabel: interLink?.title || '',
                  key: interLink?.title || '',
                  content: (
                    <section className="p-4">
                      <AlphabetizedLinkItems
                        columnCount={columnCount}
                        itemTitle={interLink?.title || ''}
                        linkItems={interLink?.links.map((link) => ({
                          name: link.name,
                          uri: `${hostAndlang}${link.uri}`,
                        }))}
                      />
                    </section>
                  ),
                },
              ]}
              data-testid={`accordion-${index}`}
              type="multiple"
              variant="stacked"
            />
          </div>
        </div>
      ))}
      <div className=" bg-border-alt pb-4" />
    </div>
  ) : null;
};
