import type * as Types from './types';

import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const CreateGenAiConversationDocument = {
  operationName: 'createGenAiConversation',
  operationString: `mutation createGenAiConversation($language: String!) {
  createGenAiConversation(language: $language) {
    data {
      conversationId
      messageId
      content {
        message
        lists {
          title
          items {
            text
            uri
            type
          }
        }
      }
      createdAt
      createdBy
    }
    error {
      message
      code
      context
      notifications {
        fields
        message
        code
      }
    }
  }
}`,
  originalOpName: 'createGenAiConversation',
};
export function useCreateGenAiConversationMutation<TError = DXError, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGenAiConversationMutation,
    TError,
    [typeof CreateGenAiConversationDocument, Types.CreateGenAiConversationMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGenAiConversationMutation,
    TError,
    [typeof CreateGenAiConversationDocument, Types.CreateGenAiConversationMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const CreateGenAiMessageDocument = {
  operationName: 'createGenAiMessage',
  operationString: `mutation createGenAiMessage($language: String!, $input: GuestGenAiCreateMessageInput!) {
  createGenAiMessage(language: $language, input: $input) {
    data {
      conversationId
      messageId
      content {
        message
        lists {
          title
          items {
            text
            uri
            type
          }
        }
        title
      }
      createdAt
      createdBy
    }
    error {
      message
      code
      context
      notifications {
        fields
        message
        code
      }
    }
  }
}`,
  originalOpName: 'createGenAiMessage',
};
export function useCreateGenAiMessageMutation<TError = DXError, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGenAiMessageMutation,
    TError,
    [typeof CreateGenAiMessageDocument, Types.CreateGenAiMessageMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGenAiMessageMutation,
    TError,
    [typeof CreateGenAiMessageDocument, Types.CreateGenAiMessageMutationVariables],
    TContext
  >({
    ...options,
  });
}
