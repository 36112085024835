import { Dialog } from '@dx-ui/osc-dialog-v2';
import * as React from 'react';
import cx from 'classnames';
import type { FormDataStructure } from '@dx-ui/osc-form';
import { Form, FormConnector } from '@dx-ui/osc-form';
import type { UseFormReturn } from 'react-hook-form';
import { InitialConversationSkeletonLoader } from './genai-chat.initial-conversation-skeleton-loader';
import { ChatBubbleLoading } from './genai-chat.chat-bubble-loading';
import {
  CreateGenAiConversationDocument,
  CreateGenAiMessageDocument,
  useCreateGenAiConversationMutation,
  useCreateGenAiMessageMutation,
} from './gql/queries';
import type { CreateGenAiMessageMutation, CreateGenAiMessageMutationVariables } from './gql/types';
import { logError } from '@dx-ui/framework-logger';
import { ChatBubble } from './genai-chat.chat-bubble';
import { Markdown } from '@dx-ui/osc-markdown';
import { Link } from '@dx-ui/osc-link';
import { ChatDisclaimer } from './genai-chat.disclaimer';
import { ButtonLists } from './genai-chat.button-lists';

export type GenaiChatProps = {
  guestId: number;
} & Pick<React.ComponentProps<typeof Dialog>, 'isOpen' | 'onDismiss' | 'dialogTrigger'>;

export type GenaiChatMessage = NonNullable<
  CreateGenAiMessageMutation['createGenAiMessage']
>['data'];

/**
 *
 * THE GENERAL AI CHAT COMPONENT IS CURRENTLY A PROOF OF CONCEPT. DO NOT USE THIS IN ANY APPLICATION UNTIL THE
 * COMPONENT IS MARKED AS "READY"
 */
export function GenaiChat(props: GenaiChatProps) {
  const [chatHistory, setChatHistory] = React.useState<Partial<GenaiChatMessage>[]>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const lastMessageRef = React.useRef<HTMLLIElement>(null);
  const chatMutation = useCreateGenAiMessageMutation();
  const initialChatMutation = useCreateGenAiConversationMutation();

  /** Set initial chat history */
  React.useEffect(() => {
    startNewChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startNewChat = () => {
    initialChatMutation.mutate([CreateGenAiConversationDocument, { language: 'en' }], {
      onSuccess: (data) => {
        setChatHistory([data?.createGenAiConversation?.data]);
      },
      onError: (error) => {
        logError('genai chat', error, 'Failed to start new conversation:');
      },
    });
  };

  /** scroll into view new chat message each time chat updates */
  React.useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [chatHistory]);

  const handleSubmit = (data: FormDataStructure, { reset }: UseFormReturn) => {
    if (!initialChatMutation.data?.createGenAiConversation?.data?.conversationId) {
      logError('genai chat', new Error('No conversation id found'));
      return;
    }
    const chatContent: Partial<GenaiChatMessage> = {
      createdBy: 'user',
      content: { message: data['chat-input'], lists: [] },
      messageId: crypto.randomUUID(),
    };
    setChatHistory((chatHistory) => [...(chatHistory ?? ([] as GenaiChatMessage[])), chatContent]);

    const variables: CreateGenAiMessageMutationVariables = {
      language: 'en',
      input: {
        conversationId: initialChatMutation.data?.createGenAiConversation?.data?.conversationId,
        guestId: props.guestId,
        prompt: data['chat-input'],
      },
    };

    chatMutation.mutate([CreateGenAiMessageDocument, variables], {
      onSuccess: (llmData) => {
        setChatHistory((chatHistory) => [
          ...(chatHistory ?? ([] as GenaiChatMessage[])),
          llmData.createGenAiMessage?.data,
        ]);
        inputRef.current?.focus();
      },
    });

    reset();
  };

  const handleDismiss = () => {
    // Per current design chat is not persisted when modal is closed
    setChatHistory([initialChatMutation.data?.createGenAiConversation?.data]);
    props.onDismiss?.();
  };

  const handleStartOver = () => {
    return () => {
      setChatHistory([]);
      initialChatMutation.reset();
      startNewChat();
      inputRef.current?.focus();
    };
  };

  const handleButtonClick = (itemText: string) => {
    const variables: CreateGenAiMessageMutationVariables = {
      language: 'en',
      input: {
        //TODO if this is ever nullish we need to throw an error
        conversationId:
          initialChatMutation.data?.createGenAiConversation?.data?.conversationId ?? '',
        guestId: props.guestId,
        prompt: itemText,
      },
    };

    setChatHistory((chatHistory) => [
      ...(chatHistory ?? ([] as GenaiChatMessage[])),
      {
        messageId: crypto.randomUUID(),
        role: 'user',
        content: { message: itemText, lists: [] },
      },
    ]);

    chatMutation.mutate([CreateGenAiMessageDocument, variables], {
      onSuccess: (llmData) => {
        setChatHistory((chatHistory) => [
          ...(chatHistory ?? ([] as GenaiChatMessage[])),
          llmData.createGenAiMessage?.data,
        ]);
        inputRef.current?.focus();
      },
    });
  };
  return (
    <Dialog
      {...props}
      className="flex h-[calc(100%-30px)] flex-col"
      dialogClassName="h-full"
      onDismiss={handleDismiss}
      title="AI Stay Planner"
      size="xl"
    >
      <ol aria-live="polite" className="flex grow flex-col space-y-4 overflow-auto">
        {initialChatMutation.isPending ? <InitialConversationSkeletonLoader /> : null}
        {chatHistory?.map((chat, index) => {
          if (!chat?.content?.message) return null;
          const buttonLists = chat?.content.lists?.filter((list) =>
            list.items.every((item) => item.type === 'button')
          );
          const nonButtonList = chat?.content.lists?.filter(
            (list) => !list.items.every((item) => item.type === 'button')
          );
          return (
            <React.Fragment key={chat?.messageId}>
              <ChatBubble
                //This is a bad assumption but if we dont get something we default to user
                chatRole={chat?.createdBy ?? 'user'}
                ref={
                  index === chatHistory.length - 1 && chatHistory.length > 1
                    ? lastMessageRef
                    : undefined
                }
              >
                {chat.content?.title ? (
                  <h3 className="pb-1 text-2xl font-bold leading-9">{chat.content.title}</h3>
                ) : null}
                {/* TODO: NHCSEARCH-5638 */}
                <Markdown
                  language="en"
                  origin="https://www.test.hilton.com"
                  options={{ wrapper: 'div' }}
                >
                  {chat?.content.message}
                </Markdown>
                {/* exclude lists that contain bubble type for now. those are only related to prompt suggestions for now. Eventually we should split up initial response into its own bubble */}
                {nonButtonList.map((list, index) => (
                  <React.Fragment key={list.title ?? index}>
                    {list.title ? <h4 className="font-bold">{list.title}</h4> : null}
                    <ul className="ml-2 list-inside list-disc">
                      {list?.items?.map((item) => (
                        <li key={item.text}>
                          {item?.uri && item.type === 'link' ? (
                            <Link className="text-text break-words" url={item.uri} isNewWindow>
                              {item.text}
                            </Link>
                          ) : null}
                          {item?.type === 'plaintext' ? item.text : null}
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
              </ChatBubble>
              {buttonLists.length > 0 ? (
                <li>
                  <ButtonLists lists={buttonLists} onClick={handleButtonClick} />
                </li>
              ) : null}
              {index === 0 ? (
                <li>
                  <ChatDisclaimer />
                </li>
              ) : null}
              {chatMutation.isPending && index === chatHistory.length - 1 ? (
                <ChatBubbleLoading ref={lastMessageRef} />
              ) : null}
            </React.Fragment>
          );
        })}
      </ol>
      <div className="bg-bg w-full pt-4">
        <Form
          className="bg-bg relative flex h-10 max-w-xl gap-x-4"
          onSubmit={handleSubmit}
          useFormProps={{ mode: 'onChange' }}
        >
          <FormConnector>
            {({ formState, register }) => {
              const { ref, ...rest } = register('chat-input', { required: true });
              return (
                <>
                  <label
                    className="bg-bg absolute -top-3 left-6 px-1 text-sm font-bold"
                    htmlFor="chat-input"
                  >
                    Type a response...
                  </label>
                  <input
                    {...rest}
                    autoComplete="off"
                    defaultValue=""
                    id="chat-input"
                    type="text"
                    className="grow rounded border p-2"
                    ref={(e) => {
                      ref(e);
                      //@ts-expect-error RHF has internal ref. so you have to assign your ref this way https://www.react-hook-form.com/faqs/#Howtosharerefusage
                      inputRef.current = e;
                    }}
                  />
                  <button
                    disabled={!formState.isValid || initialChatMutation.isPending}
                    type="submit"
                    className={cx('btn btn-primary h-10 w-28 rounded-r p-2', {
                      'btn-disabled': !formState.isValid,
                    })}
                  >
                    Send
                  </button>
                </>
              );
            }}
          </FormConnector>
        </Form>
        <div className="flex justify-between py-2">
          <div className="flex h-full items-start">
            <button
              className="btn btn-primary-link text-base"
              type="button"
              onClick={handleStartOver()}
            >
              Start Over
            </button>
            <button
              className="btn btn-primary-link ml-2 text-base"
              type="button"
              onClick={() => {
                if (chatHistory) {
                  navigator.clipboard.writeText(JSON.stringify(chatHistory)).catch((error) => {
                    logError('genai chat', error, 'Failed to copy chat history to clipboard');
                  });
                  alert('Copied chat history to the clipboard');
                }
              }}
            >
              Copy chat to clipboard
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default GenaiChat;
