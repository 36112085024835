import { DefaultLocationsPage } from '../../components/default-locations-page';
import { TransactionViewWrapper } from '../../components/view-transactions/transactions-view-wrapper';
import type { GetServerSidePropsContext } from 'next';
import {
  serverSideGeocodePageQuery,
  serverSideAllRegionsQuery,
} from '@dx-ui/queries-dx-shop-search-ui';
import { AppProvider } from '../../providers/app-provider';
import { serverSideProps } from '../../utils/serverSideProps';
import { COMMON_NAMESPACES, LOCATIONS_NAMESPACES } from '../../constants';
import { getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import { FilterProvider } from '../../providers/filter-provider';
import {} from '@dx-ui/utilities-feature-toggles-configs';

export default function DefaultLocations({ isOneLink }: { isOneLink: boolean }) {
  return (
    <FilterProvider>
      <AppProvider isOneLink={isOneLink}>
        <DefaultLocationsPage />
        <TransactionViewWrapper />
      </AppProvider>
    </FilterProvider>
  );
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const requestedLanguage = getServerSideSafeLanguage(context);
  const language = requestedLanguage || 'en';

  return serverSideProps({
    context,
    namespaces: [...COMMON_NAMESPACES, ...LOCATIONS_NAMESPACES],
    getServerSideQueries({ queryClient }) {
      return [
        serverSideAllRegionsQuery(queryClient, { language }).catch((e) => {
          console.log(`Error in serverSideAllRegionsQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
        serverSideGeocodePageQuery(queryClient, {
          language,
          pageType: 'DEFAULT',
          path: '/locations',
        }).catch((e) => {
          console.log(`Error in serverSideGeocodePageQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
      ];
    },
  });
};
