import type {
  GuestGenAiMessageContentList,
  GuestGenAiMessageContentListItem,
} from '@dx-ui/gql-types';

type ButtonListsProps = {
  lists: (Pick<GuestGenAiMessageContentList, 'title'> & {
    items: Array<Pick<GuestGenAiMessageContentListItem, 'text' | 'uri' | 'type'>>;
  })[];
  onClick: (text: string) => void;
};

export const ButtonLists = ({ lists, onClick }: ButtonListsProps) => {
  if (!lists?.length) return null;

  return lists.map((list, index) => (
    <ul
      key={list.title ?? index}
      className="flex list-none flex-col items-end justify-between space-y-2 sm:flex-row sm:space-y-0"
    >
      {list?.items?.map((item) => (
        <li key={item.text}>
          <button
            type="button"
            className="btn btn-primary-outline w-fit px-4 py-2"
            onClick={() => onClick(item.text)}
          >
            {item.text}
          </button>
        </li>
      ))}
    </ul>
  ));
};
