import type { TDynamicGridItemWOM } from '@dx-ui/osc-dynamic-grids';
import type {
  CmsImageVariant,
  TopMarketPlacesQuery,
  TopPromoPlacesQuery,
} from '@dx-ui/queries-dx-shop-search-ui';
import { getOptimizedCMSImages } from '../../../utils';
import type { TFunction } from 'i18next';

const isValidImageVariant = (
  variant: string,
  variants?: Record<string, CmsImageVariant[]>
): variant is keyof typeof variants => {
  return variants ? variant in variants : false;
};

const getDynamicGridItems = (
  reelData: TopMarketPlacesQuery['topMarketPlaces'] | TopPromoPlacesQuery['topPromoPlaces'],
  t: TFunction<['default-locations-page'], undefined>,
  imageVariant: 'fullWidth' | 'topMarket'
): TDynamicGridItemWOM[] => {
  return reelData.slice(0, 4).map((place) => {
    const variants = place?.images[0]?.image?.variants;

    const imageArray: Array<CmsImageVariant> =
      variants && isValidImageVariant(imageVariant, variants) ? variants[imageVariant] : [];

    const { url } = getOptimizedCMSImages(imageArray, 'md');
    const imageAltTxt = place?.images?.[0]?.altText ?? '';
    const linkUrl = place?.links?.[0]?.linkUrl ?? '';
    return {
      imageUrl: url ?? '',
      id: imageAltTxt ?? '',
      imageAltTxt,
      segmentIds: [],
      headline: place?.name ?? '',
      link: {
        url: linkUrl,
        label: t('exploreHotelsReels', { location: place?.links?.[0]?.linkLabel }),
      },
    };
  });
};

export const getTopPromoDynamicGridItems = (
  reelData: TopPromoPlacesQuery['topPromoPlaces'],
  t: TFunction<['default-locations-page'], undefined>
): TDynamicGridItemWOM[] => getDynamicGridItems(reelData, t, 'fullWidth');

export const getTopMarketDynamicGridItems = (
  reelData: TopMarketPlacesQuery['topMarketPlaces'],
  t: TFunction<['default-locations-page'], undefined>
): TDynamicGridItemWOM[] => getDynamicGridItems(reelData, t, 'topMarket');
