import { useIsClient } from 'usehooks-ts';
import type { TopMarketPlacesQuery, TopPromoPlacesQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useTopPromoPlacesQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useGetTopMarketPlaces } from './use-get-top-market-places';
import { useMemo } from 'react';

const getTopPromoPlaces = (
  queryResults: TopPromoPlacesQuery | undefined,
  topMarketPlaces: TopMarketPlacesQuery['topMarketPlaces']
): TopPromoPlacesQuery['topPromoPlaces'] => {
  const results: TopPromoPlacesQuery['topPromoPlaces'] = [];
  if (!queryResults || !queryResults.topPromoPlaces || queryResults.topPromoPlaces.length === 0) {
    return results;
  }

  queryResults.topPromoPlaces.forEach((tpm) => {
    const isDuplicateLocation = !!topMarketPlaces.find(
      (tmp) => tmp.name?.toLowerCase() === tpm.name?.toLowerCase()
    );
    const hasName = !!tpm?.name;
    const hasLinkLabel = !!tpm?.links[0]?.linkLabel;
    const hasLinkUrl = !!tpm?.links[0]?.linkUrl;
    const hasImages = !!tpm?.images[0]?.image?.variants?.fullWidth?.length;

    if (hasName && hasLinkLabel && hasLinkUrl && hasImages && !isDuplicateLocation) {
      results.push(tpm);
    }
  });

  return results.slice(0, 7);
};

export const useGetTopPromoPlaces = () => {
  const isClient = useIsClient();
  // Query to getTopPromoPlaces (Dynamic Tiles)
  const { data, isLoading } = useTopPromoPlacesQuery(
    {
      language: 'en',
    },
    { enabled: isClient }
  );

  const { topMarketPlaces } = useGetTopMarketPlaces();

  const topPromoPlaces = useMemo(
    () => getTopPromoPlaces(data, topMarketPlaces),
    [data, topMarketPlaces]
  );

  return { isLoading, topPromoPlaces };
};
