import { useLocation } from '@dx-ui/framework-location-provider';
import { useIsClient } from 'usehooks-ts';
import type { TopMarketPlacesQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useTopMarketPlacesQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useMemo } from 'react';

const getTopMarketPlaces = (
  queryResults: TopMarketPlacesQuery | undefined
): TopMarketPlacesQuery['topMarketPlaces'] => {
  const results: TopMarketPlacesQuery['topMarketPlaces'] = [];
  if (!queryResults || !queryResults.topMarketPlaces || queryResults.topMarketPlaces.length === 0) {
    return results;
  }

  queryResults.topMarketPlaces.forEach((tpm) => {
    const hasName = !!tpm?.name;
    const hasLinkLabel = !!tpm?.links[0]?.linkLabel;
    const hasLinkUrl = !!tpm?.links[0]?.linkUrl;
    const hasImages = !!tpm?.images[0]?.image?.variants?.topMarket?.length;

    if (hasName && hasLinkLabel && hasLinkUrl && hasImages) {
      results.push(tpm);
    }
  });

  return results;
};

export const useGetTopMarketPlaces = () => {
  const { marketingBiasCoordinate } = useLocation();
  const isClient = useIsClient();

  // Query to getTopMarketPlaces (Dynamic Tiles) depending on the users location co-ordinates
  const { data, isLoading } = useTopMarketPlacesQuery(
    {
      input: {
        coordinate: marketingBiasCoordinate,
        variant: 'topMarketPlaces',
      },
      language: 'en',
    },
    {
      enabled: isClient,
    }
  );

  const topMarketPlaces = useMemo(() => getTopMarketPlaces(data), [data]);

  return { isLoading, topMarketPlaces: topMarketPlaces?.slice(0, 7) };
};
